<template>
  <div class="row mx-0">
    <div class="col-12 p-0" v-if="myGroups && myGroups.length > 0">
      <!-- TABLE HEADING -->
      <div class="row m-0">
        <!--MOBILE-->
        <div class="col-12 d-md-none">
          <div class="row font13 green-text-dark">
            <div class="col-6 bold text-center px-1">
              Group
            </div>
            <div class="col bold text-center px-1">
              Date
            </div>
            <div class="col bold text-center px-1">
              Info
            </div>
          </div>
        </div>
        <!--END MOBILE-->
        <!--DESKTOP-->
        <div class="col-12 d-none d-md-block">
          <div class="row font13 green-text-dark">
            <div class="col-md-3 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-left">
                GROUP NAME
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3">
                GROUP ADMIN
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3">
                FOUNDED
              </div>
            </div>
            <div class="col bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-right">
                INFO
              </div>
            </div>
          </div>
        </div>
        <!--END DESKTOP-->
      </div>
      <!-- END TABLE HEADING -->
      <GroupsManageItem v-for="(group, key) in myGroups.slice(start, finish)" :key="key" :item="group" :thekey="key" @showDetails="showDetails(key)" @editName="editGroupName(key)" @deleteGroup="showDeleteGroupConfirm(key)" @showMembers="showMembers(key)" />
      <transition name="slideup">
      <GroupMembersPopup v-if="isViewGroupMembers" :item="currentItem" :theKey="currentKey" :totalItems="myGroups.length" @close="closeDetails" @goForward="goForward(currentKey)" @goBack="goBack(currentKey)" @addNew="addNewMember(currentKey)" @removeMember="removeMember" />
      </transition>
      <transition name="slideup">
      <GroupMobilePopup v-if="isViewMobile" :item="currentItem" :theKey="currentKey" :totalItems="myGroups.length" @close="closeDetails" @addNew="addNewMember(currentKey)" @editName="editGroupName(currentKey)" @deleteGroup="showDeleteGroupConfirm(currentKey)" @removeMember="removeMember" />
      </transition>
      <!-- PAGINATION -->
      <div class="row mx-0 mt-4" v-if="totalPages > 1">
        <div class="col-12 text-center">
          <div class="d-inline p-2 cursor" v-for="index in totalPages" :key="index" @click="goToPage(index)" :class="{bold: currentPage === index - 1}">
            {{ index }}
          </div>
        </div>
      </div>
      <!-- END PAGINATION -->
      <transition name="fade">
        <Popup @close="closeEditGroupName" v-if="isEditGroupName">
          <div class="row mx-0 justify-content-center">
            <div class="col-12 text-center mb-3 green-text bold font19">
              Change Group Name
            </div>
            <div class="col-12 text-center mb-3">
              Enter the new name you would like (at least 4 characters):
            </div>
            <div class="col-11 text-center mb-3">
              <TextField type="text" color="gold" placeholder="Group name here..." v-model="tempName" id="name" />
            </div>
            <div class="col-11 text-center">
              <Button color="gold" btnText="Update Name" icon="arrow" @buttonClicked="updateName" :disabled="!tempName || tempName.length < 4" :isLoading="groupsStatus === 'editing'">
                <ios-checkmark-icon class="inline-icon" />
              </Button>
            </div>
          </div>
        </Popup>
      </transition>
      <transition name="fade">
        <Popup @close="closeInvite" v-if="isAddNewMember">
          <div class="row mx-0 justify-content-center" v-if="groupMembersStatus === 'added'">
            <div class="col-6 text-center">
              <img src="@/components/animations/tickAnimation.gif" v-if="isApp" class="img-fluid" />
              <TickAnimation v-else />
            </div>
            <div class="col-12 text-center mb-3 green-text bold font19">
              Donor Invite Sent
            </div>
            <div class="col-11 text-center mb-3">
              Donor will be added to the group once they accept the invite
            </div>
            <div class="col-11 text-center">
              <Button color="gold" btnText="Invite Another Donor" icon="arrow" @buttonClicked="updateGroupMembersStatus('ready')">
                <IconArrowForward />
              </Button>
            </div>
          </div>
          <div class="row mx-0 justify-content-center" v-else-if="groupMembersStatus === 'notFound'">
            <div class="col-6 text-center">
              <ExclamationAnimation />
            </div>
            <div class="col-12 text-center mb-3 green-text bold font19">
              Donor Not Found
            </div>
            <div class="col-11 text-center mb-3">
              Please check the email provided or enter a new email address
            </div>
            <div class="col-11 text-center mb-3">
              <TextField type="email" color="gold" placeholder="email@email.com" v-model="newMember" id="email" />
            </div>
            <div class="col-11 text-center">
              <Button color="gold" btnText="Retry Invite" icon="arrow" @buttonClicked="sendInvite(currentItem.donorGroupID)">
                <IconArrowForward />
              </Button>
            </div>
          </div>
          <div class="row mx-0 justify-content-center" v-else>
            <div class="col-12 text-center mb-3 green-text bold font19">
              Invite Donor to Group
            </div>
            <div class="col-12 text-center mb-3">
              Please enter the registered email for the donor you wish to invite:
            </div>
            <div class="col-11 text-center mb-3">
              <TextField type="email" color="gold" placeholder="email@email.com" v-model="newMember" id="email" />
            </div>
            <div class="col-11 text-center">
              <Button color="gold" btnText="Send Invite Email" icon="arrow" @buttonClicked="sendInvite(currentItem.donorGroupID)" :isLoading="groupMembersStatus === 'adding'">
                <IconArrowForward />
              </Button>
            </div>
          </div>
        </Popup>
      </transition>
      <transition name="fade">
        <Popup @close="isDeleteGroup = false" v-if="isDeleteGroup">
          <div class="row mx-0 justify-content-center">
            <div class="col-12 text-center mb-3 green-text bold font19">
              Delete Group
            </div>
            <div class="col-12 text-center mb-3">
              Are you sure you would like to delete {{currentItem.donorGroupName}}
            </div>
            <div class="col-md-6 px-1 mb-1 text-center">
              <Button color="grey" btnText="Keep Group" width="100" @buttonClicked="isDeleteGroup = false" />
            </div>
            <div class="col-md-6 px-1 text-center">
              <Button color="red" btnText="Delete Group" width="100" @buttonClicked="reallyDeleteGroup" />
            </div>
          </div>
        </Popup>
      </transition>
      <transition name="fade">
        <Popup @close="closePopup" v-if="isExitGroup">
          <div class="row mx-0 justify-content-center">
            <div class="col-12 text-center mb-3 green-text bold font19">
              Remove Member from Group
            </div>
            <div class="col-12 text-center mb-3">
              Are you sure you would like to remove {{memberToRemove.member.firstName}} {{memberToRemove.member.lastName}}
            </div>
            <div class="col-6 px-1 text-center">
              <Button color="grey" btnText="Keep Member" width="100" @buttonClicked="closePopup" />
            </div>
            <div class="col-6 px-1 text-center">
              <Button color="red" btnText="Removing Member..." width="100" disabled v-if="groupMembersStatus === 'removing'" />
              <Button color="red" btnText="Remove Member Group" width="100" @buttonClicked="reallyRemoveMember" v-else />
            </div>
          </div>
        </Popup>
      </transition>
    </div>
    <div class="col-12" v-else>
      <div class="row mx-0 my-3 justify-content-center align-items-center" v-if="groupsStatus !== 'ready'">
        <div class="col-auto ps-0">
          {{groupsStatus}} Groups
        </div>
        <div class="col-auto p-0">
          <div class="spinner-border spinner-border-sm green-text" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div class="m-3 text-center" v-else>
        You don't manage any groups, try adding a group.
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    ExclamationAnimation: defineAsyncComponent(() => import('../components/animations/ExclamationAnimation.vue')),
    TextField: defineAsyncComponent(() => import('../components/TextField.vue')),
    TickAnimation: defineAsyncComponent(() => import('../components/animations/TickAnimation.vue')),
    GroupMembersPopup: defineAsyncComponent(() => import('./GroupMembersPopup.vue')),
    GroupMobilePopup: defineAsyncComponent(() => import('./GroupMobilePopup.vue')),
    GroupsManageItem: defineAsyncComponent(() => import('./GroupsManageItem.vue')),
    Popup: defineAsyncComponent(() => import('./Popup.vue')),
    IconArrowForward: defineAsyncComponent(() => import('../components/icons/IconArrowForward.vue')),
    'ios-checkmark-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-checkmark.vue'))
  },
  name: 'Groups Managed By Me',
  data () {
    return {
      itemsPerPage: 4,
      currentPage: 0,
      currentItem: null,
      memberToRemove: null,
      tempName: null,
      currentKey: null,
      isEditGroupName: false,
      isViewGroupMembers: false,
      isAddNewMember: false,
      newMember: null,
      isDeleteGroup: false,
      isExitGroup: false,
      isViewMobile: ''
    }
  },
  computed: {
    ...mapGetters(['user', 'groups', 'myGroups', 'groupMembersStatus', 'groupsStatus', 'isApp']),
    totalPages () {
      return Math.ceil(this.myGroups.length / this.itemsPerPage)
    },
    start () {
      return this.currentPage * this.itemsPerPage
    },
    finish () {
      return this.start + this.itemsPerPage
    }
  },
  methods: {
    ...mapActions(['addGroupMembers', 'checkUser', 'editGroup', 'deleteGroup', 'setMembersStatus', 'removeGroupMember']),
    goToPage (val) {
      this.currentPage = val - 1
    },
    editGroupName (val) {
      this.isEditGroupName = true
      this.currentItem = this.myGroups[val]
      this.tempName = this.currentItem.donorGroupName
    },
    closeEditGroupName (val) {
      this.isEditGroupName = false
      this.currentItem = null
      this.currentKey = null
      this.tempName = null
    },
    async updateName () {
      const details = {
        groupID: this.currentItem.donorGroupID,
        groupName: this.tempName,
        founderUserID: this.user.userID
      }
      await this.editGroup(details)
      this.isEditGroupName = false
    },
    showDeleteGroupConfirm (val) {
      this.isDeleteGroup = true
      this.currentItem = this.myGroups[val]
    },
    async reallyDeleteGroup () {
      await this.deleteGroup(this.currentItem.donorGroupID)
      this.isDeleteGroup = false
      this.currentItem = null
      this.currentKey = null
    },
    removeMember (val) {
      this.isExitGroup = true
      this.memberToRemove = val
    },
    async reallyRemoveMember () {
      const details = {
        groupID: this.currentItem.donorGroupID,
        memberID: this.memberToRemove.donorGroupMembershipID
      }
      await this.removeGroupMember(details)
      this.closePopup()
    },
    closePopup () {
      this.memberToRemove = null
      this.isExitGroup = false
    },
    addNewMember (val) {
      this.isAddNewMember = true
      this.currentItem = this.myGroups[val]
    },
    sendInvite (val) {
      const sendDetails = {
        groupID: val,
        email: this.newMember
      }
      this.checkUser(sendDetails)
    },
    async updateGroupMembersStatus (val) {
      await this.setMembersStatus(val)
    },
    closeInvite () {
      this.isAddNewMember = false
      this.updateGroupMembersStatus('ready')
    },
    showDetails (val) {
      this.isViewMobile = true
      this.currentKey = this.start + val
      this.currentItem = this.myGroups[this.currentKey]
    },
    showMembers (val) {
      this.isViewGroupMembers = true
      this.currentKey = this.start + val
      this.currentItem = this.myGroups[this.currentKey]
    },
    goForward (val) {
      this.currentItem = this.myGroups[val + 1]
      this.currentKey = val + 1
    },
    goBack (val) {
      this.currentItem = this.myGroups[val - 1]
      this.currentKey = val - 1
    },
    closeDetails () {
      this.isViewGroupMembers = false
      this.isViewMobile = false
      this.currentItem = null
      this.currentKey = null
    }
  }
}
</script>

<style scoped>
.rounded-left {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}
.rounded-right {
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
@media (min-width: 992px) {
  .rounded-left {
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
  }
  .rounded-right {
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
  }
}
.slideup-enter-active,
.slideup-leave-active {
  transition: all 0.5s ease;
}

.slideup-enter-from,
.slideup-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
</style>
